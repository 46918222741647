import { colorPalette } from "gx-npm-common-styles";

const featureDescriptionPopperStyles = {
  root: {
    border: `solid 1px ${colorPalette.neutrals.silver.hex}`,
    boxShadow: " 0px 4px 12px 0px rgba(20, 19, 18, 0.4)",
    marginLeft: 105,
    zIndex: 1000,
    "& .gx-popper-arrow": {
      boxSizing: "border-box",
      height: "1.2em",
      overflow: "hidden",
      position: "absolute",
      width: "1.8em",
      "&::before": {
        backgroundColor: colorPalette.basic.white.hex,
        border: `solid 2px ${colorPalette.neutrals.silver.hex}`,
        content: '""',
        display: "block",
        height: "100%",
        margin: "auto",
        transform: "rotate(45deg)",
        width: "100%",
        zIndex: "1001",
      },
    },
    // reference for styles: https://stackblitz.com/edit/poppers-with-arrows?file=index.js
    '&[x-placement*="bottom"]': {
      marginTop: -14,
    },
    '&[x-placement*="bottom"] .gx-popper-arrow': {
      left: "160px !important",
      marginLeft: "4px",
      marginRight: "4px",
      marginTop: "-1.2em",
      top: "0",
      "&::before": {
        transformOrigin: "0 100%",
      },
    },
    '&[x-placement*="top"] .gx-popper-arrow': {
      left: "160px !important",
      marginLeft: "4px",
      marginRight: "4px",
      bottom: "-19px",
      "&::before": {
        transformOrigin: "0 0",
        transform: "rotate(-45deg)",
      },
    },
  },
  sectionWrapper: {
    backgroundColor: colorPalette.basic.white.hex,
    color: colorPalette.neutrals.carbon.hex,
    maxHeight: "332px",
    overflowY: "auto" as const,
    position: "relative" as const,
    width: "360px",
    padding: "20px",
  },
  nameWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
  closeBtn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  descriptionWrapper: {
    borderBottom: `solid 1px ${colorPalette.neutrals.silver.hex}`,
    color: colorPalette.neutrals.coal.hex,
    marginBottom: "16px",
    paddingBottom: "16px",
    width: "292px",
  },
  topProductsTitle: {
    color: colorPalette.neutrals.iron.hex,
    marginBottom: "8px",
    width: "292px",
  },
  topProductList: {
    marginBottom: "0px",
    marginTop: "0px",
    paddingLeft: "0px",
    "& li:not(:last-of-type)": {
      marginBottom: "16px",
    },
  },
  topProductItem: {
    listStyle: "none",
  },
  productLink: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
      "& > $productName": {
        background: colorPalette.interactions.mildBerry.hex,
        borderBottom: `1px solid ${colorPalette.interactions.blackBerry.hex}`,
        color: colorPalette.interactions.blackBerry.hex,
      },
    },
  },
  productLinkGCOM3695: {
    "&:hover": {
      "& > $productName": {
        borderBottom: `1px solid ${colorPalette.interactions.hoverCta.hex}`,
        color: colorPalette.interactions.hoverCta.hex,
      },
    },
  },
  productLogo: {},
  productName: {
    color: colorPalette.neutrals.carbon.hex,
    maxWidth: "284px",
  },
  listFade: {
    background: `linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)`,
    bottom: "0",
    height: "24px",
    left: "0",
    position: "fixed" as const,
    width: "100%",
    zIndex: 1001,
  },
};

export { featureDescriptionPopperStyles };
