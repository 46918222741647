import { colorPalette } from "gx-npm-common-styles";

const aboutVendorStyles = {
  linkRoot: {
    alignSelf: "flex-start",
    backgroundColor: colorPalette.basic.white.hex,
    borderRadius: 8,
    color: colorPalette.neutrals.coal.hex,
    padding: 32,
    position: "relative" as const,
    marginBottom: 24,
  },
  linkRootGCOM3606: {
    color: "unset",
  },
  productWebsite: {
    marginTop: 20,
    marginBottom: 4,
    color: colorPalette.neutrals.iron.hex,
  },
  errorState: {
    display: "inline-flex",
    "& svg": {
      minHeight: 24,
      minWidth: 24,
    },
    "& p": {
      display: "inline",
      marginLeft: 10,
    },
  },
  errorStateText: {
    display: "inline",
    marginLeft: 10,
  },
  noAboutVendor: {
    borderRadius: 4,
    background: colorPalette.neutrals.tin.hex,
    display: "flex",
    alignItems: "start",
    gap: 20,
    padding: "20px 36px 20px 24px",
    marginTop: 16,
  },
  noAboutVendorMsg: {
    color: colorPalette.neutrals.iron.hex,
  },
};

export { aboutVendorStyles };
