import React, { Fragment } from "react";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Header,
  Paragraph,
  ProductLogo,
  TypographyComponent,
} from "gx-npm-ui";
import NotEntitledSVG from "../../../assets/images/not-entitled.svg";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { notEntitledStyles as styles } from "./not-entitled.styles";
import AlertIcon from "./alert.icon";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

const useStyles = makeStyles(() => styles);

type NotEntitledProps = {
  imageLoc: string;
  productName: string;
};

const NotEntitled: React.FC<NotEntitledProps> = ({ imageLoc, productName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={classes.productDescription}>
        <ProductLogo imageWidth="48px" imageHeight="48px" logo={imageLoc} name={productName} />
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent boldness={"semi"} styling={"h3"}>
              {productName}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Header boldness="semi" type="h3">
              {productName}
            </Header>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      <div className={classes.noFeatures}>
        <div className={classes.noFeaturesDesc}>
          <AlertIcon />
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent boldness={"medium"} styling={"p3"} color={"coal"} rootClassName={classes.title}>
                {t(
                  "This insight is only available for Gartner licensed users, to access features and functionality please contact your Gartner Account Manager."
                )}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph boldness="medium" type="p3" rootClassName={classes.title}>
                {t(
                  "This insight is only available for Gartner licensed users, to access features and functionality please contact your Gartner Account Manager."
                )}
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        <img src={NotEntitledSVG} alt="Not entitled" />
      </div>
    </Fragment>
  );
};

export default NotEntitled;
