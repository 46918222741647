import React, { useContext } from "react";
import { Paragraph, TextLink, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { aboutVendorStyles as styles } from "./about-vendor.styles";
import { IconErrorData } from "../../ui/icons";
import noAboutVendor from "../../assets/images/no-about-vendor.svg";
import { ProductOverviewContext } from "../../context/product-overview.context";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvent } from "../../app.constants";

const useStyles = makeStyles(() => styles);

const AboutVendor: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { productUrl, initId, initProdId, isError, isFromProductProfile, productId, productName } =
    useContext(ProductOverviewContext);
  const captureEvents = useCaptureEventsV2();

  /**
   * @param url
   */
  const fetchProductHost = (url: string): string => {
    try {
      return new URL(url).host;
    } catch (exception) {
      // Unable to construct URL, return url as is
      return url;
    }
  };
  const handleVendorClick = (event: React.MouseEvent<HTMLAnchorElement>, url: string) => {
    event.preventDefault();
    let eventType, metaData;
    if (isFromProductProfile) {
      eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_WEBSITE_LINK_CLICKED;
      metaData = { initiativeId: initId, initProductId: initProdId, productName };
    } else {
      eventType = ClientEvent.MARKET_PRODUCT_PROFILE_WEBSITE_LINK_CLICKED;
      metaData = { productId: productId.toString(), productName };
    }
    captureEvents([{ eventType, metaData }]);
    window.open(url, "_blank");
  };
  return (
    <div className={classes.linkRoot}>
      <TypographyComponent type="p2" boldness="semi" color="carbon">
        {t("About the vendor")}
      </TypographyComponent>
      {isError && (
        <div className={classes.errorState}>
          <IconErrorData />
          <Paragraph type="p3">{t("Oops... we were unable to load this information.")}</Paragraph>
        </div>
      )}
      {!isError && !productUrl && (
        <div className={classes.noAboutVendor}>
          <img src={noAboutVendor} alt={t("no about vendor")} />
          <Paragraph rootClassName={classes.noAboutVendorMsg} type="p3">
            {t("No information available for this vendor.")}
          </Paragraph>
        </div>
      )}
      {!isError && productUrl && (
        <div>
          <Paragraph rootClassName={classes.productWebsite} type="p4">
            {t("Website")}
          </Paragraph>
          <TextLink
            aria-label={"product url"}
            href={productUrl}
            onClick={(event) => handleVendorClick(event, productUrl)}
            target={"_blank"}
            text={fetchProductHost(productUrl)}
          />
        </div>
      )}
    </div>
  );
};

export default AboutVendor;
