import React, { Fragment, useContext, useEffect, useState } from "react";
import { Collapse } from "@material-ui/core";
import classNames from "classnames";
import { useCaptureEventsV2 } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  MiniButton,
  TypographyComponent,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { ProductOverviewContext } from "../../../context/product-overview.context";
import FeatureAndRank from "./feature-and-rank/feature-and-rank.component";
import ExpandCollapseIcon from "./expand-collapse.icon";
import styles from "./mq-template-v2.styles.module.scss";
import { ClientEvent } from "../../../app.constants";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

const MIN_NUMBER_OF_FEATURES_TO_SHOW = 5;
const SESSION_PRODUCT_PROFILE_FEATURES_EXPANDED_KEY = "productProfileFeaturesListExpanded";
const MqTemplateV2Component = () => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    featsAndFuncs,
    gcomCcResId,
    isFromProductProfile,
    productCount,
    productId,
    templateId,
    productName,
    initProdId,
    initId,
  } = useContext(ProductOverviewContext);

  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (isFromProductProfile || productId <= 0 || templateId <= 0) {
      return;
    }
    const keyName = `${SESSION_PRODUCT_PROFILE_FEATURES_EXPANDED_KEY}-${templateId}-${productId}`;
    const sessionStorageExpanded = sessionStorage.getItem(keyName);
    setIsExpanded(sessionStorageExpanded === "true");
  }, [isFromProductProfile, productId, templateId]);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
    if (isFromProductProfile || productId <= 0 || templateId <= 0) {
      return;
    }
    const keyName = `${SESSION_PRODUCT_PROFILE_FEATURES_EXPANDED_KEY}-${templateId}-${productId}`;
    sessionStorage.setItem(keyName, (!isExpanded).toString());
  };

  const handlePoweredByLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (!isFromProductProfile) {
      const eventType = ClientEvent.PRODUCT_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_LINK_CLICKED;
      const metaData = { productId: productId.toString(), productName: productName };
      captureEvents([{ eventType: eventType, metaData: metaData }]);
    } else {
      const eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_LINK_CLICKED;
      const metaData = { initiativeId: initId, initProductId: initProdId, productName: productName };
      captureEvents([{ eventType: eventType, metaData: metaData }]);
    }

    window.open(`https://www.gartner.com/document/${gcomCcResId}`, "_blank");
  };

  return (
    <Fragment>
      <div className={styles.ffHeader}>
        <TypographyComponent boldness="semi" color="carbon" rootClassName={styles.title} styling="p2">
          {t("Features and functionality")} ({featsAndFuncs.length})
        </TypographyComponent>
        {gcomCcResId !== -1 && (
          <div className={styles.poweredBy}>
            <TypographyComponent rootClassName={styles.poweredByText} styling={"p5"}>
              {t("Powered by")}
            </TypographyComponent>

            <a
              href={`https://www.gartner.com/document/${gcomCcResId}`}
              onClick={handlePoweredByLinkClick}
              className={styles.poweredByLink}
            >
              <TypographyComponent
                rootClassName={styles.poweredByLinkText}
                color={"defaultCta"}
                boldness={"medium"}
                styling={"p5"}
              >
                {t("Gartner Critical Capabilities")}
              </TypographyComponent>
            </a>
          </div>
        )}
      </div>
      <TypographyComponent boldness="medium" color="iron" type="p3" rootClassName={styles.subtitle}>
        {t(
          "Features and rankings are based on Gartner Magic Quadrant research and reflect those that are the most critical for vendors in the market."
        )}
      </TypographyComponent>

      <div className={styles.featureListColumns}>
        <div className={styles.productRanking}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent boldness={"medium"} color={"coal"} styling={"p4"}>
                {t("Vendor rank")}
                <TypographyComponent color={"iron"} element={"span"}>{` (${t(
                  "out of"
                )} ${productCount})`}</TypographyComponent>
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent boldness="medium" color="coal" type="p4">
                {t("Vendor rank")}
                <TypographyComponent color="iron" type="span">{` (${t(
                  "out of"
                )} ${productCount})`}</TypographyComponent>
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      </div>
      <div role="list">
        {featsAndFuncs.slice(0, MIN_NUMBER_OF_FEATURES_TO_SHOW).map((item) => (
          <FeatureAndRank
            key={item.name}
            description={item.description}
            name={item.name}
            rank={item.rank}
            topProducts={item.topProducts}
          />
        ))}
      </div>
      <Collapse in={isExpanded}>
        <div role="list">
          {featsAndFuncs.slice(MIN_NUMBER_OF_FEATURES_TO_SHOW).map((item) => (
            <FeatureAndRank
              key={item.name}
              description={item.description}
              name={item.name}
              rank={item.rank}
              topProducts={item.topProducts}
            />
          ))}
        </div>
      </Collapse>
      <MiniButton onClick={handleClick} rootClassName={styles.expandCollapseBtn}>
        <ExpandCollapseIcon rootClassName={classNames(styles.icon, isExpanded && styles.expanded)} />
        {isExpanded ? t("Show less features") : t("Show more features")}
      </MiniButton>
    </Fragment>
  );
};

export default MqTemplateV2Component;
