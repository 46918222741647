import { colorPalette, weightPalette } from "gx-npm-common-styles";

const vendorAlternativesStyles = {
  root: {
    backgroundColor: colorPalette.basic.white.hex,
    borderRadius: 8,
    color: colorPalette.neutrals.coal.hex,
    padding: 32,
    marginTop: 24,
  },
  container: {
    "& .p2": {
      fontWeight: weightPalette.semiBold.amount,
      fontVariationSettings: weightPalette.semiBold.variation,
      color: colorPalette.neutrals.coal.hex,
      marginBottom: 24,
    },
    "& .p4": {
      marginTop: 24,
      "& strong": {
        fontWeight: weightPalette.semiBold.amount,
        fontVariationSettings: weightPalette.semiBold.variation,
        color: colorPalette.neutrals.carbon.hex,
      },
    },
  },
  noDataContainer: {
    backgroundColor: colorPalette.neutrals.tin.hex,
    height: 88,
    display: "flex",
    borderRadius: "4px",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      minHeight: 48,
      minWidth: 48,
      margin: "0 20px 0 0",
    },
  },
  errorState: {
    display: "inline-flex",
    "& svg": {
      minHeight: 24,
      minWidth: 24,
    },
    "& p": {
      display: "inline",
      marginLeft: 10,
    },
  },
  alternateVendorsContainer: {
    marginTop: 24,
    "& a:hover": {
      textDecoration: "none",
      cursor: "pointer",
    },
    "& .alternateVendorContent": {
      display: "flex",
      height: 32,
      "& .alternateVendorTitle": {
        display: "flex",
        alignItems: "center",
        marginLeft: 16,
        width: "100%",
        "& .p3": {
          fontWeight: weightPalette.semiBold.amount,
          fontVariationSettings: weightPalette.semiBold.variation,
          color: colorPalette.neutrals.carbon.hex,
        },
      },
    },
  },
  alternativeVendorButton: {
    border: "none",
    background: "none",
    width: "100%",
    display: "block",
    margin: "24px 0",
    textAlign: "left" as const,
    cursor: "pointer",
  },
  lastVendor: {
    marginBottom: 0,
  },
  logoWrapper: {
    minWidth: "32px",
    paddingRight: "20px",
  },
  logoAndNameWrapper: {
    width: "inherit",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover p": {
      color: colorPalette.interactions.blackBerry.hex,
      backgroundColor: colorPalette.interactions.mildBerry.hex,
      cursor: "pointer",
      borderBottom: `1px solid ${colorPalette.interactions.blueBerry.hex}`,
      textDecoration: "none",
      marginBottom: "-1px",
    },
  },
  logoAndNameWrapperGCOM3695: {
    "&:hover p": {
      color: colorPalette.interactions.hoverCta.hex,
      borderBottom: `1px solid ${colorPalette.interactions.hoverCta.hex}`,
    },
  },
  "@global": {
    "& .tooltipArrow": {
      "& .Product-Overview-App-MuiTooltip-arrow": {
        left: "67px !important",
      },
    },
  },
};

export { vendorAlternativesStyles };
