import React, { Fragment } from "react";
import { Paragraph, TypographyComponent } from "gx-npm-ui";
import NoFeaturesSVG from "../../../assets/images/no-features.svg";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { noFeaturesStyles as styles } from "./no-features.styles";

const useStyles = makeStyles(() => styles);

const NoFeatures = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <TypographyComponent rootClassName={classes.title} color="carbon" boldness="semi" type="p2">
        {t("Features and functionality")}
      </TypographyComponent>
      <div className={classes.noFeatures}>
        <img src={NoFeaturesSVG} alt={t("No features")} />
        <Paragraph boldness="medium" type="p3" rootClassName={classes.subTitle}>
          {t("No features are available.")}
        </Paragraph>
      </div>
    </Fragment>
  );
};

export default NoFeatures;
