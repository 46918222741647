import React, { Fragment, useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  Paragraph,
  TextLink,
  TypographyComponent,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  FeatureFlagBooleanContainer,
  useFeatureFlag,
} from "gx-npm-ui";
import { IconErrorData } from "../../ui/icons";
import noSearchResults from "../../assets/images/no-search-results.svg";
import { moreFromGartnerStyles as styles } from "./more-from-gartner.styles";
import { SearchResultType } from "../../types";
import { ProductOverviewContext } from "../../context/product-overview.context";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvent } from "../../app.constants";
import { GCOM_3574__proposalReviewFeature } from "../../lib/feature-flags";

const useStyles = makeStyles(() => styles);

const MoreFromGartner: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initId, initProdId, isFromProductProfile, productName, productId, searchResults, hasSearchResults, isError } =
    useContext(ProductOverviewContext);
  const captureEvents = useCaptureEventsV2();
  const searchForMoreURL = `https://www.gartner.com/mysearch/research/adv?${new URLSearchParams({
    termsIn: "0",
    researchStatus: "1",
    entitlement: "1",
    start: "0",
    contentType: "41,26,61,5,62,6,7,14,4,39,46,63,18,10",
    q: productName,
  })}`;

  const handleSearchResultClick = (event: React.MouseEvent<HTMLAnchorElement>, url: string) => {
    event.preventDefault();
    let eventType, metaData;
    if (isFromProductProfile) {
      eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_MORE_FROM_GARTNER_LINK_CLICKED;
      metaData = { initiativeId: initId, initProductId: initProdId, productName };
    } else {
      eventType = ClientEvent.PRODUCT_PROFILE_MORE_FROM_GARTNER_LINK_CLICKED;
      metaData = { productId: productId.toString(), productName };
    }
    captureEvents([{ eventType, metaData }]);

    window.open(url, "_blank");
  };

  const isGCOM3574Enabled = useFeatureFlag(GCOM_3574__proposalReviewFeature);
  return (
    <div className={classes.root}>
      <div className={classNames(classes.header, isGCOM3574Enabled && classes.header3574)}>
        <TypographyComponent type="p2" boldness="semi" color="carbon">
          {t("More from Gartner")}
        </TypographyComponent>
      </div>
      {isError && (
        <div className={classes.errorState}>
          <IconErrorData />
          <Paragraph type="p3">{t("Oops... we were unable to load this information.")}</Paragraph>
        </div>
      )}
      {!isError && !hasSearchResults && (
        <div className={classes.noResults}>
          <img src={noSearchResults} alt={t("no search results")} />
          <Paragraph type="p3">{t("No search results available.")}</Paragraph>
        </div>
      )}
      {!isError && hasSearchResults && (
        <FeatureFlagBooleanContainer flagName={GCOM_3574__proposalReviewFeature}>
          <FeatureFlagBooleanOn>
            <>
              <div className={classes.results}>
                {searchResults.slice(0, 2).map((item: SearchResultType) => {
                  const { title, summary, date, url } = item;
                  return (
                    <div className={classes.resultItem} key={item.title}>
                      <TextLink
                        href={url}
                        onClick={(event) => handleSearchResultClick(event, url)}
                        target={"_blank"}
                        text={title}
                      />
                      <TypographyComponent rootClassName={classes.dateText} styling="p4" boldness="medium">
                        {date}
                      </TypographyComponent>
                      <TypographyComponent rootClassName={classes.summaryText} styling="p4" boldness="regular">
                        {summary}
                      </TypographyComponent>
                    </div>
                  );
                })}
              </div>
              <div className={classes.dividerGCOM3574} />
              <TextLink
                href={searchForMoreURL}
                onClick={(event) => handleSearchResultClick(event, searchForMoreURL)}
                target={"_blank"}
                text={t("Search for more on Gartner.com")}
                variant="secondary"
              />
            </>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Fragment>
              {searchResults.map((item: SearchResultType, idx: number) => {
                const { title, summary, date, url } = item;
                return (
                  <div key={idx}>
                    <TextLink
                      href={url}
                      onClick={(event) => handleSearchResultClick(event, url)}
                      target={"_blank"}
                      text={title}
                    />
                    <Paragraph rootClassName={classes.dateText} type="p4" boldness="medium">
                      {date}
                    </Paragraph>
                    <Paragraph rootClassName={classes.summaryText} type="p4" boldness="regular">
                      {summary}
                    </Paragraph>
                    <div className={classes.divider} />
                  </div>
                );
              })}
              <TextLink
                href={searchForMoreURL}
                onClick={(event) => handleSearchResultClick(event, searchForMoreURL)}
                target={"_blank"}
                text={t("Search for more on Gartner.com")}
                variant="secondary"
              />
            </Fragment>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      )}
    </div>
  );
};
export default MoreFromGartner;
