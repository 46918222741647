import React, { Fragment } from "react";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import NoFeaturesSVG from "../../../assets/images/no-features.svg";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { noFeaturesStyles as styles } from "./no-features.styles";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

const useStyles = makeStyles(() => styles);

const NoFeatures = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={classes.title} color={"carbon"} boldness={"semi"} styling={"p2"}>
            {t("Features and functionality")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent rootClassName={classes.title} color="carbon" boldness="semi" type="p2">
            {t("Features and functionality")}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <div className={classes.noFeatures}>
        <img src={NoFeaturesSVG} alt={t("No features")} />
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent boldness={"medium"} styling={"p3"} color={"iron"}>
              {t("No features are available.")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph boldness="medium" type="p3" rootClassName={classes.subTitle}>
              {t("No features are available.")}
            </Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
    </Fragment>
  );
};

export default NoFeatures;
