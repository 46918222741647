import { colorPalette, weightPalette } from "gx-npm-common-styles";
const colorTin = colorPalette.neutrals.tin.hex;

const reviewsStyles = {
  root: {
    backgroundColor: colorPalette.basic.white.hex,
    borderRadius: 8,
    color: colorPalette.neutrals.coal.hex,
    padding: 32,
  },
  container: {
    "& .p2": {
      fontWeight: weightPalette.semiBold.amount,
      fontVariationSettings: weightPalette.semiBold.variation,
    },
  },
  title: {
    marginBottom: 22,
  },
  ratingsHeader: {
    display: "flex" as const,
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  poweredBy: {
    display: "flex" as const,
    alignItems: "center",
  },
  poweredByText: {
    marginRight: "4px",
  },
  poweredByLink: {
    textDecoration: "underline",
    textUnderlineOffset: "5px",
  },
  poweredByLinkText: {
    color: "inherit",
  },
  errorState: {
    display: "inline-flex",
    alignItems: "center",
    "& svg": {
      minHeight: 24,
      minWidth: 24,
    },
    "& p": {
      display: "inline",
      marginLeft: 10,
    },
  },
  overallRatingContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "23.27px",
  },
  overallRatingText: {
    color: colorPalette.brand.gartnerBlue.hex,
    fontWeight: weightPalette.semiBold.amount,
    fontVariationSettings: weightPalette.semiBold.variation,
    fontSize: 24,
    lineHeight: "31.2px",
    letterSpacing: "0.15px",
    marginLeft: "11.28px",
  },
  reviewContainer: {
    border: `1px solid ${colorPalette.neutrals.silver.hex}`,
    padding: "27px 24px 27px 24px",
    "& a": {
      color: colorPalette.neutrals.carbon.hex,
      "& p": {
        fontWeight: weightPalette.semiBold.amount,
        fontVariationSettings: weightPalette.semiBold.variation,
      },
    },
    "& $headline": {
      fontWeight: weightPalette.semiBold.amount,
      fontVariationSettings: weightPalette.semiBold.variation,
    },
  },
  reviewHeader: {
    display: "flex",
    alignItems: "center",

    "& p": {
      display: "inline-block",
    },
  },
  reviewHeaderMarginBottom: {
    marginBottom: "15.11px",
  },
  reviewRatingText: {
    color: colorPalette.brand.gartnerBlue.hex,
    "& p": {
      marginLeft: 8,
    },
  },
  reviewRatingDate: {
    color: colorPalette.neutrals.iron.hex,
    "& p": {
      marginLeft: 16,
    },
  },
  reviewsLink: {
    marginLeft: 20,
  },
  reviewType: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    width: 131,
    "& p": {
      display: "inline",
      marginLeft: 8,
      fontWeight: weightPalette.medium.amount,
      fontVariationSettings: weightPalette.medium.variation,
    },
    "&.favorable": {
      "& p": {
        color: colorPalette.status.darkPear.hex,
      },
    },
    "&.critical": {
      "& p": {
        color: colorPalette.status.poisonCherry.hex,
      },
    },
  },
  headline: {
    marginBottom: 8,
  },
  summary: {
    maxWidth: "600px",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    marginBottom: 15,
  },
  readMore: {
    display: "inline-flex",
    alignItems: "center",
    "& p": {
      marginRight: 9,
    },
  },
  noPeerReviews: {
    borderRadius: 4,
    background: colorTin,
    display: "flex",
    alignItems: "center",
    flexDirection: "column" as string as "column",
    gap: 44,
    paddingBottom: 24,
    paddingTop: 24,
  },
};

export { reviewsStyles };
