import React, { useContext } from "react";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TextLink,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { aboutVendorStyles as styles } from "./about-vendor.styles";
import { IconErrorData } from "../../ui/icons";
import noAboutVendor from "../../assets/images/no-about-vendor.svg";
import { ProductOverviewContext } from "../../context/product-overview.context";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvent } from "../../app.constants";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";
import classNames from "classnames";

const useStyles = makeStyles(() => styles);

const AboutVendor: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { productUrl, initId, initProdId, isError, isFromProductProfile, productId, productName } =
    useContext(ProductOverviewContext);
  const captureEvents = useCaptureEventsV2();
  const isGCOM3606FF = useFeatureFlag(GCOM_3606__fontUpdate);
  /**
   * @param url
   */
  const fetchProductHost = (url: string): string => {
    try {
      return new URL(url).host;
    } catch (exception) {
      // Unable to construct URL, return url as is
      return url;
    }
  };
  const handleVendorClick = (event: React.MouseEvent<HTMLAnchorElement>, url: string) => {
    event.preventDefault();
    let eventType, metaData;
    if (isFromProductProfile) {
      eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_WEBSITE_LINK_CLICKED;
      metaData = { initiativeId: initId, initProductId: initProdId, productName };
    } else {
      eventType = ClientEvent.MARKET_PRODUCT_PROFILE_WEBSITE_LINK_CLICKED;
      metaData = { productId: productId.toString(), productName };
    }
    captureEvents([{ eventType, metaData }]);
    window.open(url, "_blank");
  };
  return (
    <div className={classNames(classes.linkRoot, isGCOM3606FF && classes.linkRootGCOM3606)}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"p2"} boldness={"semi"} color={"carbon"}>
            {t("About the vendor")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent type="p2" boldness="semi" color="carbon">
            {t("About the vendor")}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      {isError && (
        <div className={classes.errorState}>
          <IconErrorData />
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p3"} rootClassName={classes.errorStateText}>
                {t("Oops... we were unable to load this information.")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph type="p3">{t("Oops... we were unable to load this information.")}</Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      )}
      {!isError && !productUrl && (
        <div className={classes.noAboutVendor}>
          <img src={noAboutVendor} alt={t("no about vendor")} />
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent rootClassName={classes.noAboutVendorMsg} styling={"p3"}>
                {t("No information available for this vendor.")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph rootClassName={classes.noAboutVendorMsg} type="p3">
                {t("No information available for this vendor.")}
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      )}
      {!isError && productUrl && (
        <div>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent rootClassName={classes.productWebsite} color={"iron"} styling={"p4"}>
                {t("Website")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph rootClassName={classes.productWebsite} type="p4">
                {t("Website")}
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          <TextLink
            aria-label={"product url"}
            href={productUrl}
            onClick={(event) => handleVendorClick(event, productUrl)}
            target={"_blank"}
            text={fetchProductHost(productUrl)}
          />
        </div>
      )}
    </div>
  );
};

export default AboutVendor;
