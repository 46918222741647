import React, { Fragment, ReactElement, useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  MQChallengersIcon,
  MQIcon,
  MQLeadersIcon,
  MQNichePlayersIcon,
  MQVisionariesIcon,
  SquareQuadrantIcon,
} from "gx-npm-icons";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TextLink,
  TypographyComponent,
} from "gx-npm-ui";
import { IconErrorData } from "../../ui/icons";
import noMagicQuadrant from "../../assets/images/no-magic-quadrant.svg";
import { magicQuadrantStyles as styles } from "./magic-quadrant.styles";
import { ProductOverviewContext } from "../../context/product-overview.context";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvent } from "../../app.constants";

import { GCOM_3574__proposalReviewFeature, GCOM_3606__fontUpdate } from "../../lib/feature-flags";
import { colorPalette } from "gx-npm-common-styles";

const useStyles = makeStyles(() => styles);

const mqIcons = {
  Challenger: <MQChallengersIcon />,
  Leader: <MQLeadersIcon />,
  "Niche Player": <MQNichePlayersIcon />,
  Visionary: <MQVisionariesIcon />,
} as {
  [key: string]: ReactElement;
};

type QDPositionText = {
  [key: string]: string;
};

const MagicQuadrant: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { hasMq, mq, initId, initProdId, isError, isFromProductProfile, productId, productName } =
    useContext(ProductOverviewContext);
  const captureEvents = useCaptureEventsV2();
  const { qdPosition } = mq;
  const qdPositions = ["Challenger", "Leader", "Niche Player", "Visionary"];
  const qdPositionText: QDPositionText = {
    Challenger: t("Challengers"),
    Leader: t("Leaders"),
    "Niche Player": t("Niche players"),
    Visionary: t("Visionaries"),
  };

  const renderDocURL = (docURL: string) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      let eventType, metaData;
      if (isFromProductProfile) {
        eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_MAGIC_QUADRANT_LINK_CLICKED;
        metaData = { initiativeId: initId, initProductId: initProdId, productName };
      } else {
        eventType = ClientEvent.PRODUCT_PROFILE_MAGIC_QUADRANT_LINK_CLICKED;
        metaData = { productId: productId.toString(), productName };
      }
      captureEvents([{ eventType, metaData }]);
      window.open(docURL, "_blank");
    };

    return (
      docURL !== null && (
        <TextLink
          href={docURL}
          onClick={handleClick}
          target={"_blank"}
          text={t("Learn more about this Magic Quadrant")}
          variant="secondary"
        />
      )
    );
  };

  const renderNoMagicQuadrant = () => {
    return (
      <div className={classes.noMq}>
        <img src={noMagicQuadrant} alt={t("no magic quadrant")} />
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p3"}>
              {t("This vendor is not included in a Magic Quadrant.")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <p className="p3">{t("This vendor is not included in a Magic Quadrant.")}</p>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
    );
  };

  const renderData = () => {
    if (isError) {
      return (
        <div className={classes.errorState}>
          <IconErrorData />
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p3"}>
                {t("Oops... we were unable to load this information.")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p className="p3">{t("Oops... we were unable to load this information.")}</p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      );
    }

    const { qdDesc, docURL } = mq;

    if (!hasMq || qdPosition === "NONE" || !docURL || docURL.slice(-2) === "-1") {
      return <Fragment>{renderNoMagicQuadrant()}</Fragment>;
    }

    return (
      <Fragment>
        <div className={classes.qdInfo}>
          {mqIcons[qdPosition]}
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"h4"} rootClassName={classes.qdText}>
                {qdPosition}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <h4 className={classes.qdText}>{qdPosition}</h4>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p4"} rootClassName={classNames(classes.qdDesc)} color={"coal"}>
              {qdDesc}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <p className={classNames("p4", classes.qdDesc)}>{qdDesc}</p>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        {renderDocURL(docURL)}
      </Fragment>
    );
  };

  const renderDataGCOM3574 = () => {
    if (isError) {
      return (
        <div className={classes.errorState}>
          <IconErrorData />
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p3"}>
                {t("Oops... we were unable to load this information.")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p className="p3">{t("Oops... we were unable to load this information.")}</p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      );
    }

    const { qdDesc, docURL } = mq;

    if (!hasMq || qdPosition === "NONE" || !docURL || docURL.slice(-2) === "-1") {
      return <Fragment>{renderNoMagicQuadrant()}</Fragment>;
    }

    return (
      <>
        <div className={classes.qdInfo3574}>
          <div className={classes.qdType}>
            <TypographyComponent styling="p4" rootClassName={classes.qdSubtitle}>
              {t("Position")}
            </TypographyComponent>
            <TypographyComponent styling="h4" rootClassName={classes.qdText}>
              {qdPositionText[qdPosition]}
            </TypographyComponent>
          </div>
          <div className={classes.qdContainer}>
            {qdPositions.map((position) =>
              position === qdPosition ? (
                <div className={classes.qdBoxSelected3574} key={position}>
                  <TypographyComponent rootClassName={classes.positionTextSelected} styling="p5">
                    {qdPositionText[position]}
                  </TypographyComponent>
                </div>
              ) : (
                <SquareQuadrantIcon
                  height={"76"}
                  width={"76"}
                  key={position}
                  letterSpacing="0.35px"
                  textFill={colorPalette.neutrals.coal.hex}
                  text={qdPositionText[position]}
                  fontSize="12px"
                  fontWeight="500"
                />
              )
            )}
          </div>
        </div>
        <TypographyComponent styling="p4" rootClassName={classes.qdDesc3574}>
          {qdDesc}
        </TypographyComponent>
        {renderDocURL(docURL)}
      </>
    );
  };

  return (
    <FeatureFlagBooleanContainer flagName={GCOM_3574__proposalReviewFeature}>
      <FeatureFlagBooleanOn>
        <div className={classes.rootGCOM3574}>
          <div className={classes.sectionGCOM3574}>
            <MQIcon />
            <TypographyComponent rootClassName={classes.title} styling="p2" boldness="semi" color="carbon">
              {t("Gartner Magic Quadrant")}
            </TypographyComponent>
          </div>
          {renderDataGCOM3574()}
        </div>
      </FeatureFlagBooleanOn>
      <FeatureFlagBooleanOff>
        <div className={classes.root}>
          <div className={classes.section}>
            <TypographyComponent type="p2" boldness="semi" color="carbon">
              {t("Gartner Magic Quadrant")}
            </TypographyComponent>
          </div>
          {renderData()}
        </div>
      </FeatureFlagBooleanOff>
    </FeatureFlagBooleanContainer>
  );
};
export default MagicQuadrant;
