import { colorPalette } from "gx-npm-common-styles";

const moreFromGartnerStyles = {
  root: {
    alignSelf: "flex-start",
    backgroundColor: colorPalette.basic.white.hex,
    borderRadius: 8,
    color: colorPalette.neutrals.coal.hex,
    padding: 32,
    marginTop: 20,
    position: "relative" as const,
  },
  header: {
    marginBottom: 24,
  },
  header3574: {
    marginBottom: 38,
  },
  errorState: {
    display: "inline-flex",
    "& svg": {
      minHeight: 24,
      minWidth: 24,
    },
    "& p": {
      display: "inline",
      marginLeft: 10,
    },
  },
  results: {
    display: "flex",
    marginBottom: "40px",
  },
  resultItem: {
    paddingRight: "53px",
    width: "50%",
  },
  noResults: {
    borderRadius: 4,
    background: colorPalette.neutrals.tin.hex,
    display: "flex",
    alignItems: "center",
    gap: 20,
    paddingLeft: 24,
    paddingBottom: 20,
    paddingTop: 20,
  },
  divider: {
    borderBottom: `1px solid ${colorPalette.neutrals.silver.hex}`,
    marginTop: 28,
    marginBottom: 28,
  },
  dividerGCOM3574: {
    borderBottom: `1px solid ${colorPalette.neutrals.silver.hex}`,
    marginTop: 28,
    marginBottom: 15,
  },
  summaryText: {
    paddingTop: 8,
    color: colorPalette.neutrals.coal.hex,
    overflow: "hidden" as const,
    overflowWrap: "break-word" as const,
    textOverflow: "ellipsis" as const,
    display: "-webkit-box" as const,
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical" as const,
  },
  dateText: {
    paddingTop: 10,
  },
};

export { moreFromGartnerStyles };
