import React, { useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TextLink,
  TypographyComponent,
} from "gx-npm-ui";
import { formatDate } from "../../lib/format-date";
import { IconCritical, IconEmptyStar, IconErrorData, IconFavorable, IconFullStar, IconHalfStar } from "../../ui/icons";
import { reviewsStyles as styles } from "./reviews.styles";
import { ReviewType } from "../../types";
import noPeerReviews from "../../assets/images/no-peer-reviews.svg";
import { ProductOverviewContext } from "../../context/product-overview.context";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvent } from "../../app.constants";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

type ReviewHeaderProps = {
  reviewRating: number;
  reviewDate: string;
};

type ReviewBodyProps = {
  reviewHeadline: string;
  reviewSummary: string;
  reviewURL: string;
};

const useStyles = makeStyles(() => styles);
const Reviews: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { review, hasReview, initId, initProdId, isError, isFromProductProfile, productId, productName } =
    useContext(ProductOverviewContext);
  const captureEvents = useCaptureEventsV2();
  const handleReviewsNavClick = (event: React.MouseEvent<HTMLAnchorElement>, url: string) => {
    event.preventDefault();
    if (isFromProductProfile) {
      const eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_REVIEWS_LINK_CLICKED;
      const metaData = { initiativeId: initId, initProductId: initProdId, productName: productName };
      captureEvents([{ eventType: eventType, metaData: metaData }]);
    } else {
      const eventType = ClientEvent.PRODUCT_PROFILE_REVIEWS_LINK_CLICKED;
      const metaData = { productId: productId.toString(), productName: productName };
      captureEvents([{ eventType: eventType, metaData: metaData }]);
    }

    window.open(url, "_blank");
  };
  const renderReview = (reviewData: ReviewType, reviewType = "", style = {}) => {
    const isReviewAvailable = reviewData && reviewData.reviewId !== -1;

    const renderReviewIcon = () => {
      if (reviewType === "favorable") {
        return <IconFavorable />;
      } else {
        return <IconCritical />;
      }
    };

    const renderReviewHeader: React.FC<ReviewHeaderProps> = ({ reviewRating, reviewDate }) => {
      return (
        <span>
          {renderStars(reviewRating, true)}
          <span className={classes.reviewRatingText}>
            <p className="p2">{!isNaN(reviewRating) && reviewRating.toFixed(1)}</p>
          </span>
          <span className={classes.reviewRatingDate}>
            <p className="p4">{formatDate(reviewDate)}</p>
          </span>
        </span>
      );
    };

    const renderReviewBody: React.FC<ReviewBodyProps> = ({ reviewHeadline, reviewSummary, reviewURL }) => {
      return (
        <div>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p3"} boldness={"semi"} color={"coal"} rootClassName={classes.headline}>
                {reviewHeadline}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p className={`p3 ${classes.headline}`}>{reviewHeadline}</p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p3"} rootClassName={classes.summary}>
                {reviewSummary}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p className={`p3 ${classes.summary}`}>{reviewSummary}</p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          <TextLink
            href={reviewURL}
            onClick={(event) => handleReviewsNavClick(event, reviewURL)}
            target="_blank"
            text={t("Read full review")}
            variant="secondary"
          />
        </div>
      );
    };

    return (
      <div className={classes.reviewContainer} style={style}>
        <div className={classNames(classes.reviewHeader, isReviewAvailable && classes.reviewHeaderMarginBottom)}>
          {isReviewAvailable && renderReviewHeader(reviewData)}
          {!isReviewAvailable && (
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent styling={"p3"}>{t("No reviews in this category")}</TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <p className="p3">{t("No reviews in this category")}</p>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          )}
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent element={"span"} rootClassName={`${classes.reviewType}`}>
                {renderReviewIcon()}
                <TypographyComponent
                  color={reviewType === "favorable" ? "darkPear" : "poisonCherry"}
                  styling={"p4"}
                  boldness={"medium"}
                >
                  {reviewType === "favorable" && t("Favorable review")}
                  {reviewType === "critical" && t("Critical review")}
                </TypographyComponent>
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <span className={`${classes.reviewType} ${reviewType}`}>
                {renderReviewIcon()}
                <p className="p4">
                  {reviewType === "favorable" && t("Favorable review")}
                  {reviewType === "critical" && t("Critical review")}
                </p>
              </span>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        {isReviewAvailable && renderReviewBody(reviewData)}
      </div>
    );
  };

  const renderStars = (overallRating: number, small = false) => {
    if (!overallRating) {
      return null;
    }
    const stars = [];
    const integerN = Math.floor(overallRating);
    const remainder = overallRating % 1;
    const iconProps = small ? { height: "11", width: "12", key: 0 } : { key: 0 };

    for (let i = 0; i < 5; i++) {
      iconProps.key = i;
      if (i <= integerN - 1) {
        stars.push(<IconFullStar {...iconProps} />);
      } else if (i === integerN && remainder !== 0) {
        stars.push(<IconHalfStar {...iconProps} />);
      } else {
        stars.push(<IconEmptyStar {...iconProps} />);
      }
    }

    return stars;
  };

  const renderOverallRating = () => {
    const overallRating = review.overallRating;
    const reviewCount = review.reviewCount;
    const reviewsURL = review.reviewsURL;

    return (
      overallRating && (
        <div className={classes.overallRatingContainer}>
          {renderStars(overallRating)}
          <span className={classes.overallRatingText}>{!isNaN(overallRating) && overallRating.toFixed(1)}</span>
          <span className={classes.reviewsLink}>
            <TextLink
              href={reviewsURL}
              onClick={(event) => handleReviewsNavClick(event, reviewsURL)}
              target="_blank"
              text={`${t("See")}  ${reviewCount} ${t("review(s)")}`}
            />
          </span>
        </div>
      )
    );
  };

  const handlePIClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (!isFromProductProfile) {
      const eventType = ClientEvent.PRODUCT_PROFILE_POWERED_BY_PEER_INSIGHTS_LINK_CLICKED;
      const metaData = { productId: productId.toString(), productName: productName };
      captureEvents([{ eventType: eventType, metaData: metaData }]);
    } else {
      const eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_POWERED_BY_PEER_INSIGHTS_LINK_CLICKED;
      const metaData = { initiativeId: initId, initProductId: initProdId, productName: productName };
      captureEvents([{ eventType: eventType, metaData: metaData }]);
    }

    window.open(review.reviewsURL, "_blank");
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.ratingsHeader}>
          <TypographyComponent rootClassName={classes.title} styling="p2" boldness="semi" color="carbon">
            {t("Gartner peer rating")}
          </TypographyComponent>
          {review.reviewsURL && (
            <div className={classes.poweredBy}>
              <TypographyComponent rootClassName={classes.poweredByText} styling="p5">
                {t("Powered by")}
              </TypographyComponent>
              <a href={review.reviewsURL} onClick={handlePIClick} className={classes.poweredByLink}>
                <TypographyComponent
                  rootClassName={classNames(classes.poweredByLinkText)}
                  color={"defaultCta"}
                  boldness={"medium"}
                  styling={"p5"}
                >
                  {t("Gartner Peer Insights")}
                </TypographyComponent>
              </a>
            </div>
          )}
        </div>
        {isError && (
          <div className={classes.errorState}>
            <IconErrorData />
            <p className="p3">{t("Oops... we were unable to load this information.")}</p>
          </div>
        )}
        {!isError && hasReview && (
          <div>
            {renderOverallRating()}
            {renderReview(review.mostFavorableReview, "favorable")}
            {renderReview(review.mostCriticalReview, "critical", {
              borderTop: 0,
            })}
          </div>
        )}
        {!isError && !hasReview && (
          <div className={classes.noPeerReviews}>
            <img src={noPeerReviews} alt={t("no peer reviews")} />
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent styling={"p3"}>{t("No Peer Ratings are available.")}</TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <p className="p3">{t("No Peer Ratings are available.")}</p>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
        )}
      </div>
    </div>
  );
};
export default Reviews;
